import React from "react";
import './ourhappyclients.css'
import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { HappClientsData } from './ourhappyclientData.js';
import OurHappyClientsDetails from "./ourhappyclientdetails";
import { Grid } from "@mui/material";


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1200 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1200, min: 680 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 680, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

const OurHappyClients = () => {

    const happyClients = HappClientsData && HappClientsData.map((data) =>
        <OurHappyClientsDetails id={data.id} img={data.img} service={data.service} description={data.description} clientName={data.clientName} clientDesignation={data.clientDesignation} />
    )
    return (
        <>
            <div className="happy-client-container">
            <Grid container justifyContent="center" paddingTop={3}>
                    <Grid item paddingLeft={2} xs={11.5} sm={11.9} md={10} lg={10}>
                <div className="what-client-says">
                    <div >
                        <b>What Our Client's Says ?</b>
                    </div>
                    <div>
                        Our Happy Clients
                    </div>
                </div>
                </Grid>
                </Grid>
                <Grid container justifyContent="center" paddingTop={3}>
                    <Grid item xs={12} sm={12} md={10} lg={10}>
                            <Carousel
                                additionalTransfrom={0}
                                arrows
                                autoPlaySpeed={3000}
                                centerMode={false}
                                className=""
                                containerClass="container-with-dots"
                                dotListClass=""
                                draggable
                                focusOnSelect={false}
                                infinite
                                itemClass=""
                                keyBoardControl
                                minimumTouchDrag={80}
                                pauseOnHover
                                renderArrowsWhenDisabled={false}
                                renderButtonGroupOutside={false}
                                renderDotsOutside={false}
                                responsive={responsive}
                                rewind={false}
                                rewindWithAnimation={false}
                                rtl={false}
                                shouldResetAutoplay
                                showDots={false}
                                sliderClass=""
                                slidesToSlide={1}
                                swipeable
                            >
                                {happyClients}
                            </Carousel>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
export default OurHappyClients;