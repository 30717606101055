import React, { useState } from 'react';
import { Grid } from "@mui/material";
import { Link } from 'react-router-dom';
import './subheader.css';
const SubHeader = () => {
    const [isHovered, setIsHovered] = useState(false);
    return (
        <>
            <div className='subheader-main'>
                <Grid container justifyContent="center" spacing={2} paddingTop={2} className="subheader-container">
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="drconsulation">
                            <div style={{ color: 'white' }}
                             onMouseOver={() => setIsHovered(true)}
                             onMouseLeave={() => setIsHovered(false)}
                            >
                                <div
                                   
                                >
                                    <div style={{paddingBottom:'20px'}} onClickCapture={() => setIsHovered(false)}>
                                        Dr. Consultation
                                    </div>
                                    {
                                        isHovered &&
                                        <div
                                            onMouseLeave={() => setIsHovered(false)}
                                            onMouseOver={() => setIsHovered(true)} className="overlay-div">
                                            <Link className='drconsulationlinks' to="/opdconsulation">
                                                <div onClickCapture={() => setIsHovered(false)}
                                                >
                                                    OPD Consulation
                                                </div>
                                            </Link>
                                            <Link className='drconsulationlinks' to="/virtualconsulation">
                                                <div onClickCapture={() => setIsHovered(false)}>
                                                    Virtual Consulation
                                                </div>
                                            </Link>
                                        </div>
                                    }
                                </div>
                            </div>
                        </Link>

                    </Grid>
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="labtests"><div style={{ color: 'white' }}>Lab Tests</div></Link>
                    </Grid>
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="/"><div style={{ color: 'white' }}>
                            Pharmacy</div></Link>
                    </Grid>
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="/"><div style={{ color: 'white' }}>
                            Home Care</div></Link>
                    </Grid>
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="/womencare"><div style={{ color: 'white' }}>
                            Women Care</div></Link>
                    </Grid>
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="/"><div style={{ color: 'white' }}>
                            Ayurveda</div></Link>
                    </Grid>
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="/"><div style={{ color: 'white' }}>
                            CT/MRI</div></Link>
                    </Grid>
                    <Grid item xs={4} sm={5} md={1.3}>
                        <Link to="/"><div style={{ color: 'white' }}>
                            Ambulance</div></Link>
                    </Grid>
                </Grid>
            </div >

        </>
    );
}
export default SubHeader;