import { Grid } from "@mui/material";
import React from "react";
import './virtualdocotordetails.css';

const VirtualDrDetails = ({ doctorDetails }) => {
    return (
        <>
            <Grid key={doctorDetails.id} container alignItems="center" paddingTop={5} paddingBottom={5} spacing={4} borderBottom={1}>
                <Grid item xs={10} sm={4} md={4.5} lg={3}>
                    <div className="vdocotordetailsimage">
                        <img key={doctorDetails.id} alt="" src={doctorDetails.img} />
                    </div>
                </Grid>
                <Grid item xs={10} sm={8} md={7.5} lg={5}>
                    <div>
                        <div className="vdoctorname">{doctorDetails.drName}</div>
                        <div className="vdoctordetails">{doctorDetails.speciality}</div>
                        <div className="vdoctordetails">Total Experience: {doctorDetails.experience}</div>
                        <div className="vdoctordetails">Languages- {doctorDetails.Languages}</div>
                        <div className="vdoctordetails">City- {doctorDetails.city}</div>
                        <div className="vdoctordetails">Gender- {doctorDetails.Gender}</div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4}>
                    <div className="vdoctorbookappointment">
                    <button >Book Appointment</button>
                    </div>
                    <div className="vdoctorvirtualconsult">
                    <button>Virtual Consult</button>
                        </div>
                </Grid>
            </Grid>
        </>
    );
}
export default VirtualDrDetails;