import React, { useEffect, useState } from "react";
import { Button, Grid, Modal, Box, TextField, Chip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import VerticalShadesClosedIcon from '@mui/icons-material/VerticalShadesClosed';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { modalData, Tier2Cities } from "../data/modalData";
import './modal.css';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 3,
    boxShadow: '2px 4px 14px 2px #00000026',
    border: '0',
};
const boxShadow = {
    boxShadow: '2px 4px 14px 2px #00000026',
    borderRadius: '10px'
}
const locationIcon = <LocationOnIcon />
const ModalMain = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        const timer = setTimeout(() => {
            setOpen(true)
        }, 3000)
        return () => clearTimeout(timer)
    }

    useEffect(() => {
        handleOpen()
    }, [])

    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Modal open={open}
                onclose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div sx={style}>
                <Box className="modal-box-styling" sx={style}>
                    <Grid container paddingBottom={3} alignItems="center" justifyContent="space-between">
                        <Grid item xs={10} sm={9} md={11} lg={11}>
                            <div className="select-city">Select City</div>
                        </Grid>
                        <Grid item container justifyContent="right" xs={2} sm={2} md={1} lg={1}>
                            <CloseIcon sx={{ fontSize: '25px', cursor: 'pointer' }} onClick={handleClose} />
                        </Grid>
                    </Grid>
                    <div className="modal-boxshadow">
                        <Grid container alignItems="center" padding={1} justifyContent="center"  marginTop={5} sx={boxShadow}>
                            <Grid item borderRight={1} paddingLeft={2} xs={12} sm={6} md={5} lg={5}>
                                <div className="modal-div-display-center">
                                    {locationIcon}
                                    <input
                                        placeholder="Search City"
                                        className="modal-search-input" />
                                </div>
                            </Grid>
                            <Grid item borderRight={1} paddingLeft={2} xs={12} sm={6} md={4} lg={4}>
                                <div className="modal-div-display-center">
                                    {locationIcon}
                                    <input
                                        placeholder="Search Pincode"
                                        className="modal-search-input" />
                                </div>
                            </Grid>
                            <Grid item paddingLeft={2} xs={12} sm={12} md={1} lg={1}>
                                <div className="icon-style" >
                                    <VerticalShadesClosedIcon sx={{ fontSize: '30px' }} />
                                </div>

                            </Grid>
                        </Grid>
                        <Grid container marginTop={5}>
                            <div className="font-size-20">Popular Cities</div>
                            <Grid item container paddingTop={2} justifyContent="center">
                                {
                                    modalData && modalData.map((data) => {
                                        return (
                                            <Grid item margin={1.5} paddingBottom={2} sx={boxShadow} xs={10} sm={5} md={3} lg={1.72}>
                                                <div className="modal-locations" key={data.id}>
                                                    <div className="modal-image-style">
                                                        <img key={data.id} alt="" src={data.img} />
                                                    </div>
                                                    <div className="modal-location-avaiable">{data.serviceAvailible}</div>
                                                </div>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                        <Grid container paddingTop={5}>
                            <div className="font-size-20">Popular Tier-2 Cities</div>
                            <Grid item container paddingTop={2} justifyContent="start">
                                {
                                    Tier2Cities && Tier2Cities.map((data) => {
                                        return (
                                            <Grid item margin={1.5} paddingBottom={2} xs={12} sm={5} md={3.3} lg={1.72}>
                                                <div className="chip-width" key={data.id}>
                                                    <Chip sx={{ width: '100%', height: '100%', fontSize: '17px', fontWeight: '600', backgroundColor: '#075fab', color: 'white'}} label={data.city} />
                                                </div>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Grid>
                    </div>
                </Box>
                </div>
            </Modal>
        </>
    );
}
export default ModalMain;