import { Grid, Button } from "@mui/material";
import React from "react";
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import './allTest.css';
import { Link} from "react-router-dom";

const ratingStyle={
    fontSize:'22px'
}
const AllTestDetails = (props) => {
    return (
        <>
            <div style={{ padding: '20px' }}>
                <div className="packageOff">
                    44% off
                </div>
                <div className="packageName1">
                    {props?.item?.test_name}
                </div>
                <div className="package-rating">
                    <StarOutlineIcon sx={ratingStyle} />
                    <StarOutlineIcon sx={ratingStyle} />
                    <StarOutlineIcon sx={ratingStyle} />
                    <StarOutlineIcon sx={ratingStyle} />
                    <StarOutlineIcon sx={ratingStyle} />
                </div>
                <div className="packageName2" >
                    {props?.item?.test_name}
                </div>
                <div className="package-price">
                    {`₹${props?.item?.mbt_price}/-`}
                </div>

                <Grid container justifyContent="start" spacing={4} paddingBottom={2} paddingTop={2} >
                    <Grid item xs={4} sm={5} md={4.2} lg={4.2}>
                        <div
                            style=
                            {{
                                fontSize: '18px',
                                fontWeight: '800',
                                border: '1px',
                                width: '100%'
                            }}>
                            <Button variant="contained" style={{width:'100%',fontSize:'15px',fontWeight:'600',backgroundColor:'#075eab'}}>
                                Add
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={4} sm={5} md={4.2} lg={4.2}>
                        <div
                        key={props?.item?._id}
                            style={{
                                fontSize: '18px',
                                fontWeight: '800',
                                border: '1px',
                            }}>
                            <Link to={`/labtests/singleproductpage/${props?.item?._id}`}><Button  variant="contained" style={{ width: '100%', fontSize: '15px', fontWeight: '600', backgroundColor: '#075eab' }}>
                                View
                            </Button></Link>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}
export default AllTestDetails;