export const opdServiceData = [
    {
        id: 1,
        img: 'https://www.visitcompletecare.com/wp-content/uploads/2019/06/shutterstock_1487854622-1-scaled.webp',
        serviceAvailible: 'Fever'
    },
    {
        id: 2,
        img: 'https://static.toiimg.com/photo/msid-75449076/75449076.jpg',
        serviceAvailible: 'Coughing'
    },
    {
        id: 3,
        img: 'https://medlineplus.gov/images/Diabetes_share.jpg',
        serviceAvailible: 'Diabetes'
    },
    {
        id: 4,
        img: 'https://img.freepik.com/free-photo/close-up-man-rubbing-his-painful-back-isolated-white-background_1150-2935.jpg',
        serviceAvailible: 'Muscle Aches'
    },
    {
        id: 5,
        img: 'https://images.pexels.com/photos/4474052/pexels-photo-4474052.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4474052.jpg&fm=jpg',
        serviceAvailible: 'Weight Loss'
    },
]

export const NearestOPDConsulation=[
    {
        id:1,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    },
    {
        id:2,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    },
    {
        id:3,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    },
    {
        id:4,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    },
    {
        id:5,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    },
    {
        id:6,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    },
    {
        id:7,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    },
    {
        id:8,
        img:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSypTm4tMxR0UJX1W4kUe5YjGjRF7sKOClE8A&usqp=CAU',
        hospitalName:'Shree Clinic',
        hospitalLocation:'auto stand, 15/2, Netaji st, near camal mahal, kalarampatti, Salem',
    }
]