export const modalData = [
    {
        id: 1,
        img: 'https://www.visitcompletecare.com/wp-content/uploads/2019/06/shutterstock_1487854622-1-scaled.webp',
        serviceAvailible: 'Pune'
    },
    {
        id: 2,
        img: 'https://static.toiimg.com/photo/msid-75449076/75449076.jpg',
        serviceAvailible: 'Mumbai'
    },
    {
        id: 3,
        img: 'https://medlineplus.gov/images/Diabetes_share.jpg',
        serviceAvailible: 'Banglore'
    },
    {
        id: 4,
        img: 'https://img.freepik.com/free-photo/close-up-man-rubbing-his-painful-back-isolated-white-background_1150-2935.jpg',
        serviceAvailible: 'Chennai'
    },
    {
        id: 5,
        img: 'https://images.pexels.com/photos/4474052/pexels-photo-4474052.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4474052.jpg&fm=jpg',
        serviceAvailible: 'Hyderabad'
    },
    {
        id: 6,
        img: 'https://images.pexels.com/photos/4474052/pexels-photo-4474052.jpeg?cs=srgb&dl=pexels-ketut-subiyanto-4474052.jpg&fm=jpg',
        serviceAvailible: 'New Delhi'
    },
]


export const Tier2Cities=[
    {
        id:1,
        city:'AGRA'
    },
    {
        id:2,
        city:'AGRA'
    },
    {
        id:3,
        city:'AGRA'
    },
    {
        id:4,
        city:'AGRA'
    },
    {
        id:5,
        city:'AGRA'
    },
    {
        id:6,
        city:'AGRA'
    },
    {
        id:7,
        city:'AGRA'
    },
    {
        id:8,
        city:'AGRA'
    },
    {
        id:9,
        city:'AGRA'
    },
    {
        id:10,
        city:'AGRA'
    },
    {
        id:11,
        city:'AGRA'
    },
    {
        id:12,
        city:'AGRA'
    },
    {
        id:13,
        city:'AGRA'
    },
    {
        id:14,
        city:'AGRA'
    },
    {
        id:15,
        city:'AGRA'
    },
]