export const MediPartnerData=[
    {
        id:1,
        img:"https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/medi-logo.jpg",
        partnerLabs:'ABC certified',
        partnerName:'MediBioTest Labs (covid RTPCR)',
        partnerLocation:'Rajasthan',
    },
    {
        id:2,
         img:"https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/medi-logo.jpg",
        partnerLabs:'ABC certified',
        partnerName:'MediBioTest Labs (covid RTPCR) ',
        partnerLocation:'Rajasthan',
    },

]