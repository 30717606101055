import { Grid } from '@mui/material';
import React from 'react';

const SpecialistDrDetails = (props) => {
    console.log("props",props.drName)
    return (
        <>
            <div key={props.id} style={{  borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#f8f8f8', marginLeft: '20px', height: '220px',display:'flex',justifyContent:'center' }}>
                <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <div style={{height:'80px',width:'80px',display:'flex',justifyContent:'center'}}>
                        <img style={{height:'100%',width:'100%',borderRadius:'50%'}} alt="" src={props.img} />
                        </div>
                    <div style={{ fontSize: '22px', fontWeight: '700', color: '#075fab', marginTop: '10px' }}>
                        {props.drName}
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '600', marginTop: '5px' }}>
                        {props.drspeciality}
                    </div>
                    <div style={{ fontSize: '16px',fontWeight: '600', color: '#02a44e', marginTop: '5px'}}>
                        {props.experience} Years Experience
                    </div>
                </div>
            </div>
        </>
    );
}
export default SpecialistDrDetails;

