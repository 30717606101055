export const HappClientsData=[
    {
        id:1,
        service:'Good Service',
        description:'The team were really helpfull in getting the doctor alligned when we were detected with covid-19 and the dr. were so helpful she guided us throughout the episod of Covid and helped us withh all small and major quiries. Absolutely helpful team and I would realtty recommand their service to others.',
        img:"https://t4.ftcdn.net/jpg/05/11/55/91/360_F_511559113_UTxNAE1EP40z1qZ8hIzGNrB0LwqwjruK.jpg",
        clientName:'Sanjay Mishra',
        clientDesignation:'Product Head',
    },
    {
        id:2,
        service:'Good Service',
        description:'The team were really helpfull in getting the doctor alligned when we were detected with covid-19 and the dr. were so helpful she guided us throughout the episod of Covid and helped us withh all small and major quiries. Absolutely helpful team and I would realtty recommand their service to others.',
        img:"https://t4.ftcdn.net/jpg/05/11/55/91/360_F_511559113_UTxNAE1EP40z1qZ8hIzGNrB0LwqwjruK.jpg",
        clientName:'Sanjay Mishra',
        clientDesignation:'Product Head',
    },
    {
        id:3,
        service:'Good Service',
        description:'The team were really helpfull in getting the doctor alligned when we were detected with covid-19 and the dr. were so helpful she guided us throughout the episod of Covid and helped us withh all small and major quiries. Absolutely helpful team and I would realtty recommand their service to others.',
        img:"https://t4.ftcdn.net/jpg/05/11/55/91/360_F_511559113_UTxNAE1EP40z1qZ8hIzGNrB0LwqwjruK.jpg",
        clientName:'Sanjay Mishra',
        clientDesignation:'Product Head',
    },
    {
        id:4,
        service:'Good Service',
        description:'The team were really helpfull in getting the doctor alligned when we were detected with covid-19 and the dr. were so helpful she guided us throughout the episod of Covid and helped us withh all small and major quiries. Absolutely helpful team and I would realtty recommand their service to others.',
        img:"https://t4.ftcdn.net/jpg/05/11/55/91/360_F_511559113_UTxNAE1EP40z1qZ8hIzGNrB0LwqwjruK.jpg",
        clientName:'Sanjay Mishra',
        clientDesignation:'Product Head',
    },
    {
        id:5,
        service:'Good Service',
        description:'The team were really helpfull in getting the doctor alligned when we were detected with covid-19 and the dr. were so helpful she guided us throughout the episod of Covid and helped us withh all small and major quiries. Absolutely helpful team and I would realtty recommand their service to others.',
        img:"https://t4.ftcdn.net/jpg/05/11/55/91/360_F_511559113_UTxNAE1EP40z1qZ8hIzGNrB0LwqwjruK.jpg",
        clientName:'Sanjay Mishra',
        clientDesignation:'Product Head',
    },
]