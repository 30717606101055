import { Grid } from "@mui/material";
import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { specialist } from "./specialistDrData";
import './drconsulation.css'
import SpecialistDrDetails from "./specialistdrDetails";
import { specilistTypeDetails } from "./specialist/specificationsData";
import NextCaresol from "./specialist/nextCaresol";
import SpecificationsDetails from "./specialist/specificationsDetails";
import { Link } from "react-router-dom";
import ConsultaionQueries from "./virtualconsulation/anyquesries";
const DrConsulation = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    const responsive1 = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5,
            slidesToSlide: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
            slidesToSlide: 4
        },
        tablet: {
            breakpoint: { max: 1024, min: 800 },
            items: 3,
            slidesToSlide: 3
        },
        smalltable:{
            breakpoint: { max: 800, min: 500 },
            items: 2,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    const responsive2 = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6,
            slidesToSlide: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 5,
            slidesToSlide: 5
        },
        tablet: {
            breakpoint: { max: 1024, min: 800 },
            items: 3,
            slidesToSlide: 3
        },
        smalltable:{
            breakpoint: { max: 800, min: 500 },
            items: 2,
            slidesToSlide: 2
        },
        mobile: {
            breakpoint: { max: 500, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };

    const drDetails = specialist.map((details) =>
        <>
            <SpecialistDrDetails id={details.id} img={details.img} drName={details.drName} drspeciality={details.drspeciality} experience={details.experience} />
            {console.log("details", details.id)}
        </>
    )

    const specialistDetails = specilistTypeDetails.map((details) =>
        <SpecificationsDetails id={details.id} img={details.img} specility={details.specility} />
    )
    return (
        <>
            <div className="drconsulation-carousel-style" >
                <div className='container' >
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        <div >
                            <img style={{ height: '300px', width: '100%' }} alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/banner1.png" />
                        </div>
                        <div >
                            <img style={{ height: '300px', width: '100%' }} alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/banner2.png" />
                        </div>
                    </Carousel>
                </div>
            </div>

            <Grid container justifyContent="center" paddingTop={5}>
              <Grid item xs={9} sm={9} md={9} lg={8.5}>
              <div className="specialist">Specialist
                    <br/>Our <b>Doctors</b></div>
              </Grid>
            </Grid>
            <div className="drconsulation-carousel-style">
                <div className="container"  >
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive1}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                            {drDetails}
                    </Carousel>
                </div>
            </div>
            <div style={{ marginTop: '40px', width: '100%', boxSizing: 'border-box', padding: '30px', backgroundColor: '#f0fbf7' }}>
                <Grid container justifyContent="center">
                    <div className="top-specialist">
                        <b>20+ Specialities</b>
                        <br/>
                        Consult with top doctors across specialities
                </div>
                </Grid>
                <div className="drconsulation-carousel-style" >
                    <div className='container'>
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        pauseOnHover
                        renderArrowsWhenDisabled={false}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={responsive2}
                        rewind={false}
                        rewindWithAnimation={false}
                        rtl={false}
                        shouldResetAutoplay
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        {specialistDetails}
                    </Carousel>
                </div>
                </div>
            </div>
            <Grid container justifyContent="center" marginTop={5} spacing={4}>
                <Grid item xs={10} sm={9} md={3.5} lg={3.5}>
                    <div>
                        <div className="artical">
                            Read top Articles
                            from heatlth experts
                        </div>
                        <div className="artical-details">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                        </div>
                    </div>
                </Grid>
                <Grid item xs={10} sm={5} md={3.5} lg={3.5}>
                 <Link to="/opdconsulation">
                 <div className="consulation" >
                        <div className="consulation-image">
                            <img className="image-style" alt="" src="https://soodgmhospitalkarnal.in/wp-content/uploads/2022/04/opd-02.jpg" />
                        </div>
                   <div className="opd-consulation">OPD Consultation</div>
                    </div>
                 </Link>
                </Grid>
                <Grid item xs={10} sm={5} md={3.5} lg={3.5}>
                <Link to="/virtualconsulation">
                    <div className="consulation">
                        <div className="consulation-image">
                            <img className="image-style" alt="" src="https://www.gigadocs.com/blog/wp-content/uploads/2020/05/close-up-business-man-sit-home-having-online-consultation-with-doctor-tablet-reduce-social-distance-healthcare-concept_43157-1034.jpg" />
                        </div>
                   <div className="opd-consulation">Virtual Consultation</div>
                    </div>
                    </Link>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" paddingTop={10}>
                <ConsultaionQueries/>
            </Grid>
        </>
    )
}
export default DrConsulation;