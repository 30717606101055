import React from "react";
import { Carousel } from 'react-responsive-carousel';
import './slider.css'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import SearchPackages from "./subheader/labtests/testpackages/searchPackages";
import MediPartner from "./medipartner";

const homemain={
  height:'470px',
  width:'100%'
}
export const Home = (props) => {
  return (
    <>
    <div id="home" >
      <div >
        <Carousel stopOnHove={true} autoPlay={true} interval={3000} transitionTime={3000} infiniteLoop showThumbs={false}>
                    <img style={homemain} alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/banner1.png" />
                    <img style={homemain} alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/banner2.png" />
                    <img style={homemain} alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/banner3.png" />
            </Carousel>
      </div>
    </div>
    <SearchPackages />
      <MediPartner/>
    </>
  );
};
