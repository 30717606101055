export const specilistTypeDetails = [
    {
        id: 1,
        img: "https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning.jpg",
        specility: 'Dermatologist',
    },
    {
        id: 2,
        img: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning.jpg',
        specility: 'Dermatologist',
    },
    {
        id: 3,
        img: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning.jpg',
        specility: 'Dermatologist',
    },
    {
        id: 4,
        img: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning.jpg',
        specility: 'Dermatologist',
    },
    {
        id: 5,
        img: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning.jpg',
        specility: 'Dermatologist',
    },
    {
        id: 6,
        img: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning.jpg',
        specility: 'Dermatologist',
    },
    {
        id: 7,
        img: 'https://www.yourdentistryguide.com/wp-content/uploads/2017/11/tooth-cleaning.jpg',
        specility: 'Dermatologist',
    },
]