import { Grid } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GetCallBack from "./getCallBack";
import './footer.css'

const Footer = () => {
    return (
        <>
            <div className="footer-margin-top">
                <Grid sx={{ backgroundColor: '#075fab', color: 'white' }} container spacing={2} direct="row" marginTop={5} justifyContent="center">
                   <div className="footer-upper-modal-height">
                    <Grid item justifyContent="center" padding={3} xs={12} sm={12} md={12} lg={12}>
                        <Grid item alignItems="center" xs={12} sm={12} md={10} lg={10}>
                            <div className="lets-talk-health">
                                Let's<br />
                                <b> TALK ABOUT YOUR HEALTH</b>
                            </div>
                        </Grid>
                        <Grid item container alignItems="center" paddingTop={2} xs={12} sm={12} md={12} lg={12}>
                            <Grid item xs={12} sm={12} md={9} lg={9}>
                                <div className="helpline-contact">
                                    Feel free to call us on our medical Helpline number +91 95551 66000.
                                    <br />
                                    To register as a partner with us click here
                                </div>
                            </Grid>
                            <Grid item padding={1} xs={12} sm={12} md={3} lg={2}>
                                <button className="get-in-touch-button">GET IN TOUCH</button>
                            </Grid>
                        </Grid>
                    </Grid>
                    </div>
                    <Grid item xs={12} sm={6} md={3} lg={3} marginTop={5} marginBottom={2}>
                        <img alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/medi-logo.jpg" style={{ height: '30%', width: '100%' }} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} marginTop={5} marginBottom={2}>
                        <div style={{ fontSize: '16px' }}>
                            <div style={{ padding: '5px' }}>About Us</div>
                            <div style={{ padding: '5px' }}>  Media</div>
                            <div style={{ padding: '5px' }}> Career</div>
                            <div style={{ padding: '5px' }}>Money Back Policy</div>
                            <div style={{ padding: '5px' }}>Feedback/Complaints</div>
                            <div style={{ padding: '5px' }}> Our Corporate Clients</div>
                            <div style={{ padding: '5px' }}>Become Business partner</div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} marginTop={5} marginBottom={2}>
                        <div style={{ fontSize: '16px' }}>
                            <div style={{ padding: '5px' }}>Our Labs</div>
                            <div style={{ padding: '5px' }}>Contact Us</div>
                            <div style={{ padding: '5px' }}>Blog</div>
                            <div style={{ padding: '5px' }}>Investers</div>
                            <div style={{ padding: '5px' }}>Scan Lab</div>
                            <div style={{ padding: '5px' }}>FAQ</div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2} marginTop={5} marginBottom={2}>
                        <div style={{ fontSize: '25px', fontWeight: '600' }}>Follow Us</div>
                        <Grid container spacing={2} marginTop={2}>
                            <Grid item xs={2} sm={4} md={2}><FacebookRoundedIcon sx={{ fontSize: "33px", padding: '5px',borderRadius: '50%', backgroundColor: '#f27d27', color: 'white' }} /></Grid>
                            <Grid item xs={2} sm={4} md={2}><TwitterIcon sx={{ fontSize: "30px", padding: '5px', borderRadius: '50%', backgroundColor: '#f27d27', color: 'white' }} /></Grid>
                            <Grid item xs={2} sm={4} md={2}><LinkedInIcon sx={{ fontSize: "30px", padding: '5px', borderRadius: '50%', backgroundColor: '#f27d27', color: 'white' }} /></Grid>
                            <Grid item xs={2} sm={4} md={2}><YouTubeIcon sx={{ fontSize: "30px", padding: '5px', borderRadius: '50%', backgroundColor: '#f27d27', color: 'white' }} /></Grid>
                            <Grid item xs={2} sm={4} md={2}><InstagramIcon sx={{ fontSize: "30px", padding: '5px', borderRadius: '50%', backgroundColor: '#f27d27', color: 'white' }} /></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center" style={{ backgroundColor: '#f8f8f8', border: '1px solid gray', padding: '20px', display: 'flex', justifyContent: 'center', fontWeight: '600' }}>

                    <Grid item xs={9} sm={3.3} md={3}> © 2020 Copyright: <a href="https://mdbootstrap.com/"> Medi Bio Test</a></Grid>
                    <Grid item xs={5} sm={2.1} md={2}> Terms & Conditions</Grid>
                    <Grid item xs={5} sm={1.7} md={2}>Privacy Policy</Grid>
                    <Grid item xs={5} sm={2.4} md={2}>Statutory Compliance</Grid>
                    <Grid item xs={5} sm={2.1} md={2}> Programs & Policies</Grid>
                </Grid>
                <GetCallBack />
            </div>
        </>
    );
}
export default Footer;