export const womenDoctors=[
    {
        id:1,
        img:'https://media.istockphoto.com/id/1323431279/photo/healthy-pregnancy-side-view-pregnant-woman-with-big-belly-advanced-pregnancy-in-hands-banner.jpg?s=612x612&w=0&k=20&c=UICNLtwzuyll_Pkux3WiNDik0w_g0625zjLDBJyn-Qo=',
        doctorName:'Pregnancy Care/Birthing'
    },
    {
        id:2,
        img:'https://content3.jdmagicbox.com/comp/hyderabad/n6/040pxx40.xx40.140115114546.l7n6/catalogue/dr-padmavathi-v-yashoda-hospitals-malakpet-hyderabad-gynaecologist-obstetrician-doctors-2bk95pz.jpg',
        doctorName:'Gynaecologist'
    },
    {
        id:3,
        img:'https://www.manipalhospitals.com/uploads/doctors_photo/dr-subhasini-m-obstetrics-gynaecology.png',
        doctorName:'Period Tracker'
    }
]

export const WomenCareBestPackage=[
    {
        id:1,
        img:'https://media.istockphoto.com/id/1323431279/photo/healthy-pregnancy-side-view-pregnant-woman-with-big-belly-advanced-pregnancy-in-hands-banner.jpg?s=612x612&w=0&k=20&c=UICNLtwzuyll_Pkux3WiNDik0w_g0625zjLDBJyn-Qo=',
        packageName:'Menopause',
        packageDetails:'Every women experience menopause differently. Most women enter perimenopause'
    },
    {
        id:2,
        img:'https://media.istockphoto.com/id/1323431279/photo/healthy-pregnancy-side-view-pregnant-woman-with-big-belly-advanced-pregnancy-in-hands-banner.jpg?s=612x612&w=0&k=20&c=UICNLtwzuyll_Pkux3WiNDik0w_g0625zjLDBJyn-Qo=',
        packageName:'Menopause',
        packageDetails:'Every women experience menopause differently. Most women enter perimenopause'
    },
    {
        id:3,
        img:'https://media.istockphoto.com/id/1323431279/photo/healthy-pregnancy-side-view-pregnant-woman-with-big-belly-advanced-pregnancy-in-hands-banner.jpg?s=612x612&w=0&k=20&c=UICNLtwzuyll_Pkux3WiNDik0w_g0625zjLDBJyn-Qo=',
        packageName:'Menopause',
        packageDetails:'Every women experience menopause differently. Most women enter perimenopause'
    },
    {
        id:4,
        img:'https://media.istockphoto.com/id/1323431279/photo/healthy-pregnancy-side-view-pregnant-woman-with-big-belly-advanced-pregnancy-in-hands-banner.jpg?s=612x612&w=0&k=20&c=UICNLtwzuyll_Pkux3WiNDik0w_g0625zjLDBJyn-Qo=',
        packageName:'Menopause',
        packageDetails:'Every women experience menopause differently. Most women enter perimenopause'
    },
]

export const womenCareCategory=[
    {
        id:1,
        img:'https://media.istockphoto.com/id/1323431279/photo/healthy-pregnancy-side-view-pregnant-woman-with-big-belly-advanced-pregnancy-in-hands-banner.jpg?s=612x612&w=0&k=20&c=UICNLtwzuyll_Pkux3WiNDik0w_g0625zjLDBJyn-Qo=',
        categoryName:'Pregnancy Care/Birthing'
    },
    {
        id:2,
        img:'https://content3.jdmagicbox.com/comp/hyderabad/n6/040pxx40.xx40.140115114546.l7n6/catalogue/dr-padmavathi-v-yashoda-hospitals-malakpet-hyderabad-gynaecologist-obstetrician-doctors-2bk95pz.jpg',
        categoryName:'Gynaecologist'
    },
    {
        id:3,
        img:'https://www.manipalhospitals.com/uploads/doctors_photo/dr-subhasini-m-obstetrics-gynaecology.png',
        categoryName:'Period Tracker'
    },
    {
        id:4,
        img:'https://media.istockphoto.com/id/1323431279/photo/healthy-pregnancy-side-view-pregnant-woman-with-big-belly-advanced-pregnancy-in-hands-banner.jpg?s=612x612&w=0&k=20&c=UICNLtwzuyll_Pkux3WiNDik0w_g0625zjLDBJyn-Qo=',
        categoryName:'Pregnancy Care/Birthing'
    },
    {
        id:5,
        img:'https://content3.jdmagicbox.com/comp/hyderabad/n6/040pxx40.xx40.140115114546.l7n6/catalogue/dr-padmavathi-v-yashoda-hospitals-malakpet-hyderabad-gynaecologist-obstetrician-doctors-2bk95pz.jpg',
        categoryName:'Gynaecologist'
    },
    {
        id:6,
        img:'https://www.manipalhospitals.com/uploads/doctors_photo/dr-subhasini-m-obstetrics-gynaecology.png',
        categoryName:'Period Tracker'
    }
]