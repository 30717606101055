import React from "react";
const SpecificationsDetails=(props)=>{
    return(
        <>
         <div key={props.id} style={{ border: '1px solid #B7B7B7', borderRadius: '10px', paddingBottom: '10px', backgroundColor: '#f8f8f8', marginLeft: '20px', height: '252px' }}>
                <div>
                    <div style={{height:'150px'}}>
                       <img key={props.id} style={{height:'100%',width:'100%',borderRadius:'10px'}} alt="" src={props.img} />
                    </div>
                    <div style={{ marginLeft: '15px', marginRight: '15px' }}>
                    <div style={{ fontSize: '13px', marginTop: '15px',fontSize:'20px',fontWeight:'600' }}>
                        {props.specility}
                    </div>
                    <div style={{ fontSize: '13px', marginTop: '5px'}}>
                        <button style={{backgroundColor:'transparent',color:'#02a44e',border:'0',fontSize:'17px',fontWeight:'600'}}>Consult Now</button>
                    </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default SpecificationsDetails;