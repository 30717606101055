import React from 'react';
import { Grid} from "@mui/material";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import AllTest from './alltests/allTest';
const buttonStyle={
    width:'100%',padding:'15px'
}
const LabTests = () => {

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1,
            slidesToSlide: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1
        }
    };
    return (
        <>
          <div style={{ marginTop: '40px', width: '100%', boxSizing: 'border-box' }}>
                    <div className='container' style={{margin:'auto'}} >
            <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                <div >
              <img style={{height:'300px',width:'100%'}} alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/banner1.png" />
              </div>
              <div >
              <img style={{height:'300px',width:'100%'}} alt="" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/banner2.png" />
              </div>
            </Carousel>
            </div>
            </div>
            <Grid container spacing={3} justifyContent="center" marginTop={2}>
                <Grid item xs={8} sm={5} md={1.7}>
                    <button type="button" style={buttonStyle} className="subheader-buttons">All Tests
                </button>
                </Grid>
                <Grid item xs={8} sm={5} md={1.7}>
                    <button style={buttonStyle} className="subheader-buttons">Health Packages
                    </button>
                    </Grid>
                <Grid item xs={8} sm={5} md={1.7}>
                    <button style={buttonStyle} className="subheader-buttons">
                    Upload Prescription
                    </button>
                    </Grid>
                <Grid item xs={8} sm={5} md={1.7}>
                    <button style={buttonStyle} className="subheader-buttons">Book on Call
                    </button>
                    </Grid>
            </Grid>
            <AllTest/>
        </>
    );
}
export default LabTests;