import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import { Grid } from "@mui/material";

const initialState = {
  name: "",
  email: "",
  message: "",
};
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };
  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(name, email, message);
    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div id="contactForm">
      <Grid container paddingTop={4} paddingBottom={4} paddingLeft={5} marginTop={5}>
        <Grid container spacing={2} >
          <Grid item xs={11} sm={10} md={10}>
            <h2 style={{ color: 'white' }}>Get In Touch</h2>
            <Grid item xs={1} sm={1} md={0.5}><hr style={{ backgroundColor: '#075fab', width: '50px', height: '4px' }} /></Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={11} sm={10} md={10}>
            <div style={{ color: 'white' }}> Please fill out the form below to send us an email and we will
              get back to you as soon as possible.</div>
          </Grid>
        </Grid>
        <form name="sentMessage" validate onSubmit={handleSubmit}>
          <Grid container spacing={2} marginTop={4} marginBottom={4} >
            <Grid item xs={10} sm={11} md={5}><input
              type="text"
              id="name"
              name="name"
              className="form-control"
              placeholder="Name"
              required
              onChange={handleChange}
            />
              <p className="help-block text-danger"></p></Grid>
            <Grid item xs={10} sm={11} md={5}>
              <input
                type="email"
                id="email"
                name="email"
                className="form-control"
                placeholder="Email"
                required
                onChange={handleChange}
              />
              <p className="help-block text-danger"></p></Grid>
            <Grid item xs={10} sm={11} md={10}>
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows="4"
                placeholder="Message"
                required
                onChange={handleChange}
              ></textarea>
              <p className="help-block text-danger"></p></Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={10} sm={11} md={9}>
              <button type="submit" className="btn btn-custom btn-lg">
                Send Message
              </button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </div>
  );
};
