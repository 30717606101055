import React from "react";
import { Button } from "@mui/base";
import { Grid } from "@mui/material";
const GetCallBack=()=>{
    return(
        <>
          <div style={{ padding: '20px' }}>
                <div style={{ fontSize: '20px', fontWeight: '600' , display: 'flex', justifyContent: 'center',color:'#075fab'}}>
                    Get a call back from our MediBioTest
                </div>
                <Grid container spacing={0} justifyContent="center" marginTop={2}>
                    <Grid item xs={10} sm={5} md={3}>
                    <input style={{width:'100%',height:'50px',paddingLeft:'10px'}} type="text" placeholder="Enter your 10 digit mobile number" />
                    </Grid>
                    <Grid item xs={10} sm={2} md={2}>
                    <Button style={{width:'100%',height:'50px',backgroundColor:'#28a745',color:'white',border:'0px',fontWeight:'500',fontSize:'16px'}}>Get a free Call</Button>
                    </Grid>
                </Grid>
                </div>
        </>
    );
}
export default GetCallBack;
