import React from 'react';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const MediPartnerDetails = (props) => {
    return (
        <>
            <div style={{ border: '1px solid #B7B7B7', borderRadius: '10px', backgroundColor: 'white' ,marginLeft:'20px',paddingBottom:'20px'}}>
                <div style={{ marginLeft: '15px', marginRight: '15px', marginTop: '20px' }}>
                    <div>
                        <img key={props.id} src={props.img} alt="" 
                        style={{
                            width:'150px',
                            height:'70px',
                            border:'1px solid lightgray',
                            padding:'10px',
                            borderRadius:'10px'
                            }} />
                    </div>
                    <div style={{ fontSize: '16px', fontWeight: '600', marginTop: '5px', color: 'black' }}>{props.partnerName}</div>
                    <div style={{ fontWeight: '400', marginTop: '20px', color: 'black' }}>{props.partnerLabs}</div>
                    <div style={{ fontSize: '13px', marginTop: '15px',display:'flex' }}>
                        <LocationOnIcon fontSize="large"  /> 
                        {props.partnerLocation}
                        </div>
                </div>
            </div>
        </>
    );
}
export default MediPartnerDetails;

