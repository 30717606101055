import React, { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Home } from "./components/home";
import { About } from "./components/about";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import Footer from "./components/footer";
import SubHeader from "./components/subheader/subHeader";
import LabTests from "./components/subheader/labtests/labTests";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AllTests from "./components/alltests";
import SingleProductPage from './components/subheader/labtests/alltests/singleproductpage';
import DrConsulation from "./components/subheader/drconsulation/drconsulation";
import OPDConsulation from "./components/subheader/drconsulation/opdconsulation/opdconsulation";
import VirtuaConsulation from "./components/subheader/drconsulation/virtualconsulation/virtualconsulation";
import OurHappyClients from "./components/happyclients/ourhappeclients";
import ModalMain from "./components/Modal";
import WomanCare from "./components/subheader/womencare/womencare";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);
  

  return (
    <>
      <BrowserRouter>
        <Navigation />
        <SubHeader />
        <Routes>
          <Route path="/" element={<Home data={landingPageData.Home} />} />
          <Route path="labtests" element={<LabTests />} />
          <Route path="labtests/alltests" element={<AllTests />} />
          <Route path="labtests/singleproductpage/:id" element={<SingleProductPage />} />
          <Route path="drconsulation" element={<DrConsulation/>} />
          <Route path="opdconsulation" element={<OPDConsulation />} />
          <Route path="virtualconsulation" element={<VirtuaConsulation />} />
          <Route path="womencare" element={<WomanCare />} />
        </Routes>
        <OurHappyClients />
        <ModalMain/>
        <About data={landingPageData.About} />
        <Contact data={landingPageData.Contact} />
        <Footer />
      </BrowserRouter>

    </>
  );
};

export default App;
