import { Grid } from "@mui/material";
import React,{useEffect} from "react";
import { opdServiceData } from "./opdserviceData";
import './opdconsulation.css'
import OPDConsulationsNearMe from "./opdconsulationsnearme";
import ConsultaionQueries from '../virtualconsulation/anyquesries'

const OPDConsulation = () => {
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    return (
        <>
        <Grid container justifyContent="center" padding={5}>
            <Grid item xs={10} sm={10} md={10.5} lg={10.5}>
            <div className="opdconsulation-image">
                <img alt="" src="https://www.raphacure.com/img/banners/BannerNew/Doctor-onsultation-Clinical.webp" />
            </div>
            </Grid>
        </Grid>
            <Grid container spacing={6} justifyContent="center" paddingTop={5}>
                {
                    opdServiceData && opdServiceData.map((data) => {
                        return (
                            <Grid item xs={9} sm={5} md={3.5} lg={2}>
                                <div className="opd-service-main" key={data.id}>
                                    <div className="opd-service-image">
                                        <img className="opd-service-image-style" key={data.id} alt="" src={data.img} />
                                    </div>
                                    <div className="opd-service-availibility">{data.serviceAvailible}</div>
                                </div>
                            </Grid>
                        )
                    })
                }
            </Grid>
            <OPDConsulationsNearMe />
            <Grid marginTop={5}>
            <ConsultaionQueries />
            </Grid>
        </>
    );
}
export default OPDConsulation;