import React from "react";
import { Button } from "@mui/base";
import { Grid } from "@mui/material";
import AllPackages from "./allPackages";
const SearchPackages = () => {
    return (
        <>
        <div style={{backgroundColor:'#f8f8f8',padding:'25px'}}>
            <Grid container spacing={0} justifyContent="center" >
                <Grid item xs={12} sm={8} md={6}>
                    <input
                        style={{ width: '100%', height: '50px', paddingLeft: '10px' }}
                        type="text"
                        placeholder="Find your Packages/Test/Scans" />
                </Grid>
                <Grid item xs={12} sm={2} md={1}>
                    <Button style={{ width: '100%', height: '50px',fontSize:'20px', border: '0', backgroundColor: '#02a44e', color: 'white'}}>
                          Search
                    </Button>
                </Grid>
            </Grid>
            </div>
            <AllPackages />
        </>
    );
}
export default SearchPackages;
