import React from "react";
import { Grid } from "@mui/material";

export const About = (props) => {
  return (
    <>
      <Grid container spacing={8} justifyContent="center" marginTop={3}>
        <Grid item xs={12} sm={12} md={4}>
          <img src="img/about.jpg"  className="img-responsive" alt="" />{" "}
        </Grid>
        <Grid item xs={11} sm={10} md={4.7}>            <h2>About Us</h2>
         <Grid item xs={1} sm={1} md={1}>   <hr style={{backgroundColor:'#075fab',width:'50px',height:'4px'}} /></Grid>            <p>{props.data ? props.data.paragraph : "loading..."}</p>
        </Grid>
      </Grid>


    </>
  );
};
