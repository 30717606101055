import { Grid, Modal, Button } from "@mui/material";
import React, { useState } from "react";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import PhoneEnabledIcon from '@mui/icons-material/PhoneEnabled';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 4,
  boxShadow: '2px 4px 14px 2px #00000026'
};

const buttonStyle = {
  width: '100%', height: '45px', fontSize: '15px', fontWeight: '600', backgroundColor: '#075eab', color: 'white'
}

export const Navigation = (props) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };


  return (
    <>
      <nav id="menu" className="navbar navbar-default navbar-fixed-top" style={{ width: '100%' }}>
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top" >
            <Link to="/"> <img style={{ width: "250px", height: "40px" }} src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/medi-logo.jpg" alt="logo" /></Link>
            </a>{" "}
          </div>
          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <Grid container spacing={2} justifyContent="center" direction="row" marginTop={0}>
                {/* <Grid item xs={12} sm={12} md={3}>
                  <Link to="/"><img style={{ width: "150px", height: "40px" }} src="https://meditest.in/wp-content/uploads/2022/05/Elisa-1-1.jpg" alt="logo" /></Link>
                </Grid> */}
                <Grid item xs={12} sm={12} md={2} sx={{ cursor: 'pointer', display: 'flex' }}>
                  <Link to="/" style={{ display: 'flex' }}>
                    <div> <HomeIcon
                      sx={{
                        backgroundColor: '#075fab',
                        color: 'white',
                        borderRadius: '50%',
                        padding: '4px',
                        fontSize: '30px',
                        marginRight: '10px'
                      }}
                    /></div>
                    <div style={{ color: 'black', fontWeight:'600',fontSize:'16px'}}>Home</div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={3.5} sx={{ cursor: 'pointer', display: 'flex' }}>
                  <Link to="/" style={{ display: 'flex' }}>
                    <div >
                      <LocationOnIcon
                        sx={{
                          backgroundColor: '#075fab',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '4px',
                          fontSize: '30px',
                          marginRight: '10px'
                        }} />
                    </div>
                    <div style={{ color: 'black', fontWeight:'600',fontSize:'16px' }}>
                      Your Location<KeyboardArrowDownIcon />
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={3} sx={{ cursor: 'pointer', display: 'flex' }}>
                  <Link to="/" >
                    <div style={{ display: 'flex' }} onClick={handleOpen}>
                      <div> <PersonIcon
                        sx={{
                          backgroundColor: '#075fab',
                          color: 'white',
                          borderRadius: '50%',
                          padding: '4px',
                          fontSize: '30px',
                          marginRight: '10px'
                        }}
                      /></div>
                      <div style={{ color: 'black', fontWeight:'600',fontSize:'16px' }}>Login/Signup</div>
                    </div>
                  </Link>
                </Grid>
                <Grid item xs={12} sm={12} md={3} sx={{ cursor: 'pointer', display: 'flex' }}>
                  <Link to="/" style={{ display: 'flex' }}>
                    <div> <PhoneEnabledIcon
                      sx={{
                        backgroundColor: '#075fab',
                        color: 'white',
                        borderRadius: '50%',
                        padding: '4px',
                        fontSize: '30px',
                        marginRight: '10px'
                      }}
                    /></div>
                    <div>
                      <div style={{ color: 'black', fontWeight:'600',fontSize:'16px' }}>Customer Support</div>
                      <div style={{ color: 'black'}}>6376706470/9636445401</div></div>
                  </Link>

                </Grid>
              </Grid>
            </ul>
          </div>
        </div>
      </nav>
      <Modal open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} className="box-shadow">
                    <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item xs={6} sm={6} md={11} lg={11}>
                            <div className="fontsize-20-bold">
                                Login
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={1} lg={1} justifyContent="right">
                            <CancelOutlinedIcon onClick={handleClose} style={{ color: 'green', fontSize: '25px',cursor:'pointer' }} />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" marginTop={2} className="box-shadow" >
                        <Grid item container xs={10} sm={10} md={6} lg={6} justifyContent="center" >
                            <Grid item xs={11} sm={11} md={10} lg={10}>
                                <div className="fontsize-20-bold">
                                    Mobile Number
                                </div>
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={2}>
                                <input style={{ width: '100%', fontSize: '16px', padding: '10px', borderRadius: '5px' }} placeholder="Enter Pincode here..." />
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={4}>
                                <Button variant="contained" sx={buttonStyle}>Continue</Button>
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={3} marginTop={3} borderTop={1}>
                               You will receive an OTP Shortly <br />We will send Appointment - related communication on this number
                            </Grid>
                        </Grid>
                        <Grid item container xs={10} sm={10} md={6} lg={6} paddingTop={3} paddingBottom={3} justifyContent="center" >
                            <Grid item xs={11} sm={11} md={10} lg={10}> 
                             <img style={{ height: '100%', width: '100%' }} src="https://www.hindustantimes.com/ht-img/img/2023/06/30/1600x900/national_doctors_day_1688133615866_1688133630147.jpg" alt="" />
                           
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={2}>
                             <b> Sign In</b>
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={3}>
                               Sign in with <br />One Time Password(OTP)
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
    </>
  );
};
