const isSelected = (field) => {
    if (
        field === "all" ||
        field === "" ||
        field === undefined ||
        field === null ||
        field === "none"
    ) {
        return false;
    } else {
        return true;
    }
  };
  
  export const genrateAdminFilter = (body) => {
    const { test_name, lead_id, full_name,age,gender,phone,booking_date,collection_date,start_time,end_time } = body;
    const admin_test_name = isSelected(test_name)? `&test_name=${test_name}`: "";
    const admin_lead_id = isSelected(lead_id)? `&lead_id=${lead_id}`: "";
    const admin_full_name = isSelected(full_name) ? `&full_name=${full_name}` : "";
    const admin_age = isSelected(age) ? `&age=${age}` : "";
    const admin_gender = isSelected(gender) ? `&gender=${gender}` : "";
    const admin_phone = isSelected(phone) ? `&phone=${phone}` : "";
    const admin_booking_date = isSelected(booking_date) ? `&booking_date=${booking_date}` : "";
    const admin_collection_date = isSelected(collection_date) ? `&collection_date=${collection_date}` : "";
    const admin_start_time = isSelected(start_time) ? `&start_date=${start_time}` : "";
    const admin_end_time = isSelected(end_time) ? `&end_date=${end_time}` : "";
    const url = `?${admin_test_name}${admin_lead_id}${admin_full_name}${admin_age}${admin_gender}${admin_phone}${admin_booking_date}${admin_collection_date}${admin_start_time}${admin_end_time}`;
    return url;
  };
  