import { Grid } from '@mui/material';
import React from 'react';

const PackagesDetails = (props) => {
    return (
        <>
            <div style={{ border: '1px solid #B7B7B7', borderRadius: '10px', paddingTop: '10px', paddingBottom: '10px', backgroundColor: '#f8f8f8', marginLeft: '20px',height:'350px'}}>
                <div style={{ marginLeft: '15px', marginRight: '15px'}}>
                    <div style={{ fontSize: '23px', fontWeight: '600', color: 'black' }}>
                        {props.packageName}
                        </div>
                    <div style={{ fontSize: '13px', marginTop: '35px',height:'50%',overflowY:'scroll',overflowX:'hidden',scrollbarWidth:'thin'}}>
                        {props.packageDetails}
                    </div>
                    <div >
                        <Grid container justifyContent="center" paddingBottom={2} paddingTop={3} >
                            <Grid item xs={6} sm={6} md={6}>
                                <div
                                    style=
                                    {{
                                        color: '#075eab',
                                        fontSize: '18px',
                                        fontWeight: '800',
                                        marginTop: '10px',
                                        marginBottom: '15px'
                                        , border: '1px',
                                        backgroundColor: 'white', padding: '10px',
                                        textDecorationLine: 'line-through',
                                        width: '100%'
                                    }}>
                                    {props.priceOld}
                                </div>
                            </Grid>
                            <Grid item xs={6} sm={6} md={6}>
                                <div
                                    style={{
                                        color: 'white',
                                        fontSize: '18px',
                                        fontWeight: '800',
                                        marginTop: '10px',
                                        marginBottom: '15px',
                                        border: '1px',
                                        backgroundColor: '#075eab', padding: '10px'
                                    }}>{props.priceNew}</div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
}
export default PackagesDetails;

