export const specialist = [
    {
        id: 1,
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drName: 'Dr Rahul',
        drspeciality: 'Dermologist',
        experience: '5'
    },
    {
        id: 2,
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drName: 'Dr Rahul',
        drspeciality: 'Dermologist',
        experience: '5'
    },
    {
        id: 3,
        drName: 'Dr Rahul',
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drspeciality: 'Dermologist',
        experience: '5'
    },
    {
        id: 4,
        drName: 'Dr Rahul',
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drspeciality: 'Dermologist',
        experience: '5'
    },
    {
        id: 5,
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drName: 'Dr Rahul',
        drspeciality: 'Dermologist',
        experience: '5'
    },
    {
        id: 6,
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drName: 'Dr Rahul',
        drspeciality: 'Dermologist',
        experience: '5'
    },
    {
        id: 7,
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drName: 'Dr Rahul',
        drspeciality: 'Dermologist',
        experience: '7'
    },
    {
        id: 8,
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drName: 'Dr Rahul',
        drspeciality: 'Dermologist',
        experience: '8'
    },
    {
        id: 9,
        img: 'https://img.freepik.com/free-photo/attractive-young-male-nutriologist-lab-coat-smiling-against-white-background_662251-2960.jpg',
        drName: 'Dr Rahul',
        drspeciality: 'Dermologist',
        experience: '9'
    }
]