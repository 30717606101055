import { Grid } from "@mui/material";
import React, { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './virtualconsulation.css'
const ConsultaionQueries = () => {
    const expantMoreIcon = <ExpandMoreIcon style={{ fontSize: '30px' }} />
    const [queryOneOpen, setQueryOneOpen] = useState(false);
    const queryOneToggleButton = () => {
        queryOneOpen === true ? setQueryOneOpen(false) : setQueryOneOpen(true);
    }

    const [queryTwoOpen, setQueryTwoOpen] = useState(false);
    const queryTwoToggleButton = () => {
        queryTwoOpen === true ? setQueryTwoOpen(false) : setQueryTwoOpen(true);
    }
    return (
        <>
            <div>
                <div className="vconsullt-faq">FAQ</div>
                <div className="vconsult-anyqueries">Do You Have <b>Any Queries</b></div>
            </div>
            <Grid container paddingTop={3} justifyContent="center" alignItems="center" >
                <Grid item borderBottom={1} xs={10} sm={10} md={10} lg={10}>
                    <button onClick={queryOneToggleButton} className="FAQ-questions" >
                        <Grid item container justifyContent="space-between" xs={12} sm={12} md={12} lg={12} padding={1} >
                            <Grid item container justifyContent="left" xs={9} sm={8} md={8} lg={10}>
                                <div className="detailsname">
                                    What is Virtual dr. Consulation
                                </div>
                            </Grid>
                            <Grid item container xs={3} sm={4} md={4} lg={2} justifyContent="right">
                                {expantMoreIcon}
                            </Grid>

                        </Grid>
                    </button>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    {
                        queryOneOpen ?
                            <div className="answerdetails">
                                With virtual doctor consultation you can access top doctors without leaving your house or from anywhere. This is convenient, easy, affordable and time saver.
                            </div> : ''
                    }
                </Grid>
            </Grid >

            <Grid container paddingTop={3} justifyContent="center" >
                <Grid item borderBottom={1} xs={10} sm={10} md={10} lg={10}>
                    <button onClick={queryTwoToggleButton} className="FAQ-questions" >
                        <Grid item container justifyContent="space-between" xs={12} sm={12} md={12} lg={12} padding={2} >
                            <Grid item container justifyContent="left" xs={9} sm={8} md={8} lg={10}>
                                <div className="detailsname">
                                    How many types/categories of doctors are available on Medibiotest?
                                </div>
                            </Grid>
                            <Grid item container xs={3} sm={4} md={4} lg={2} justifyContent="right">
                                {expantMoreIcon}
                            </Grid>

                        </Grid>
                    </button>
                </Grid>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    {
                        queryTwoOpen ?
                            <div className="answerdetails">
                                We have doctors from every categories such as general physician, ent, cardiologist, eye, dental, urologist, gastro, orthopaedic, neurologist, gynecologist, sexual medicine, psychology, nutrition, pathologist, kidney, respiratory, liver, heart, etc. We believe in facilitating wholistic care and hence we have doctors and therapists not just from modern medicine but also from ayurveda, homeopathy, yoga, naturopathy, acupressure and acupuncture.
                            </div> : ''
                    }
                </Grid>
            </Grid >
        </>
    );
}
export default ConsultaionQueries;