import React from "react";
import { Grid, MenuItem, Select } from "@mui/material";
import { NearestOPDConsulation } from "./opdserviceData";
import './opdconsulation.css'

const OPDConsulationsNearMe = () => {
    return (
        <>
            <Grid container justifyContent="center" alignItems="center" paddingTop={6}>
                <Grid item container xs={10} sm={7} md={7} lg={5}>
                    <div className="opd-consulation-near-me">Find <b>OPD Consultations</b> for near me</div>
                    <div className="opd-book-appointment">Book appointments with minimum wait time and verified clinics</div>
                </Grid>
                <Grid container item xs={10} sm={3} md={3} lg={5} justifyContent="right" alignItems="center">
                    <Select className="opd-consulation-select" defaultValue='all'>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="hightolow">Charges High to Low</MenuItem>
                        <MenuItem value="lowtohigh">Charges Low to High</MenuItem>
                        <MenuItem value="nearestloc">Nearest Location</MenuItem>
                    </Select>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                {
                    NearestOPDConsulation && NearestOPDConsulation.map((data) => {
                        return (
                            <>
                                <Grid item container alignItems="center" margin={2} borderBottom={1} paddingBottom={2} paddingTop={5} spacing={1} xs={10} sm={10} md={5} lg={5}>
                                    <Grid item container  xs={12} sm={3} md={3.2} lg={2.5}>
                                        <div key={data.id} className="opd-nearest-main-consulation">
                                            <img className="opd-nearesr-image-style" alt="" src={data.img} />
                                        </div>
                                    </Grid>
                                    <Grid item  xs={12} sm={7} md={12} lg={9.5}>
                                        <div className="opd-appointment-hospital-name">
                                            {data.hospitalName}
                                        </div>
                                        <div>
                                            {data.hospitalLocation}
                                        </div>
                                        <div className="opd-book-appointment">
                                            <button className="opd-book-button">Book Appointment</button>
                                        </div>
                                    </Grid>
                                </Grid>

                            </>
                        )
                    }
                    )
                }
            </Grid>
        </>
    );
}
export default OPDConsulationsNearMe;