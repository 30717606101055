
import React, { useState,useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import PackagesDetails from './packageDetails';
import axios from "axios";


const background = {
    backgroundColor: 'lightskyblue',
    paddingBottom: '40px'
}
const packageHeader = {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    marginTop: '50px',
    marginBottom: '40px',
    paddingLeft: '30px',
    boxSizing: 'border-box'
}
const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};


const AllPackages = () => {
    const [testPackage, setTestPackage] = useState([]);

        async function getData() {
            const response = await axios.get("https://api.medibiotest.com/package/getAllTopPackage")
            const result = response.data.results
            const packageDetails = result.map((item) =>
                <PackagesDetails packageName={item?.test_name} packageDetails={item.test_parameters} priceOld={item.mbt_price} priceNew={item.MRP} />
            )
            console.log(packageDetails)
            setTestPackage(packageDetails)
        }
    useEffect(() => {
        getData()
    }, []
    )

    return (
        <>
            <div style={background}>
                <div style={packageHeader}>
                    <h1>Full Body Checkup Packages</h1>
                </div>
                <div >
                    <div className='container'>
                         <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className=""
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            renderArrowsWhenDisabled={false}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={responsive}
                            rewind={false}
                            rewindWithAnimation={false}
                            rtl={false}
                            shouldResetAutoplay
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable 
                        >
                             {testPackage} 
                        </Carousel>
                    </div>
                </div>
            </div>
        </>
    );
}
export default AllPackages;

