import React from "react";
import './ourhappyclients.css'
import { StarRate } from "@mui/icons-material";

const starRate = <StarRate sx={{ fontSize: '15px', color: '#ff9200' }} />
const OurHappyClientsDetails = (props) => {
    return (
        <>
            <div key={props.id} className="happy-clients-main">
                <div className="happy-client-service">
                    {props.service}
                </div>
                <div className="happy-client-description">
                    {props.description}
                </div>
                <div className="happy-client-rating">
                    {starRate}{starRate}{starRate}{starRate}{starRate}
                </div>
                <div className="client-details">
                    <div className="happyclient-image">
                        <img alt="" src={props.img} />
                    </div>
                       <div className="client-details-right">
                       <div className="client-name">
                            {props.clientName}
                        </div>
                        <div className="client-designation">
                            {props.clientDesignation}
                        </div>
                       </div>
                </div>
            </div>
        </>
    )
}
export default OurHappyClientsDetails;