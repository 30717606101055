export const VirtualDrData=[
    {
        id:1,
        img:'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg',
        drName:'Dr. Deepthi V',
        speciality:'Diabetologist, General Physician',
        experience:'6',
        Languages:"English",
        City:"",
        Gender:"female"
    },
    {
        id:2,
        img:'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg',
        drName:'Dr. Deepthi V',
        experience:'6',
        Languages:"English",
        City:"",
        Gender:"female"
    },
    {
        id:3,
        img:'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg',
        drName:'Dr. Deepthi V',
        speciality:'Diabetologist, General Physician',
        experience:'6',
        Languages:"English",
        City:"",
        Gender:"female"
    },
    {
        id:4,
        img:'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg',
        drName:'Dr. Deepthi V',
        speciality:'Diabetologist, General Physician',
        experience:'6',
        Languages:"English",
        City:"",
        Gender:"female"
    },
    {
        id:5,
        img:'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg',
        drName:'Dr. Deepthi V',
        speciality:'Diabetologist, General Physician',
        experience:'6',
        Languages:"English",
        City:"",
        Gender:"female"
    },
    {
        id:6,
        img:'https://img.freepik.com/free-photo/pleased-young-female-doctor-wearing-medical-robe-stethoscope-around-neck-standing-with-closed-posture_409827-254.jpg',
        drName:'Dr. Deepthi V',
        speciality:'Diabetologist, General Physician',
        experience:'6',
        Languages:"English",
        City:"",
        Gender:"female"
    },

]