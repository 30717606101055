import React from 'react';
import { Grid } from "@mui/material";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { MediPartnerData } from './constants/medipartnerData';
import MediPartnerDetails from './mediPartnerDetails';

const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
        slidesToSlide: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
        slidesToSlide: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
        slidesToSlide: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};
 const myDetails = MediPartnerData.map(item => (
     <MediPartnerDetails id={item.id} img={item.img} partnerName={item.partnerName} partnerLabs={item.partnerLabs} partnerLocation={item.partnerLocation}  />
 ))

const MediPartner = () => {
    return (
        <>
            <Grid container spacing={3} marginTop={4} justifyContent="center">
                <Grid item xs={9.5} sm={10.5} md={8.7}>
                    <div style={{ fontSize: '18px', fontWeight: '500' }}>Certified Partner Labs</div>
                </Grid>
            </Grid>
            <div style={{ marginTop: '20px', width: '100%', boxSizing: 'border-box' }}>
                    <div className='container' style={{margin:'auto'}} >
            <Carousel
                additionalTransfrom={0}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                itemClass=""
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={responsive}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
            >
                {myDetails}
            </Carousel>
            </div>
                </div>
        </>
    );
}
export default MediPartner;