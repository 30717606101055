import { Button, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from "axios";
import './singleproductpage.css';
import Box from '@mui/material/Box';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 4,
    boxShadow: '2px 4px 14px 2px #00000026'
};

const buttonStyle = {
    width: '100%', height: '45px', fontSize: '15px', fontWeight: '600', backgroundColor: '#075eab', color: 'white'
}

const inputStyle = { width: '100%', height: '35px', borderRadius: '5px', paddingLeft: '10px', fontSize: '20px', color: 'lightblack' }

const SingleProductPage = () => {
    const { id } = useParams();
    console.log(id)
    const expantMoreIcon = <ExpandMoreIcon style={{fontSize:'30px'}} />
    const [singleProducts, setSingleProducts] = useState([]);
    const [singleProducts1, setSingleProducts1] = useState([]);
    const [aboutPackageOpen, setAboutPackageOpen] = useState(false);
    const [testPreparationOpen, setTestPreparationOpen] = useState(false);
    const [vendorOpen, setVendorOpen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState();
    const [city, setCity] = useState('');
    const [source, setSource] = useState('');
    const [message, setMessage] = useState('');
    const [openSuccessMessage, setOpenSuccessMessage] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setMessage('')
    };


    const packageToggleButton = () => {
        aboutPackageOpen === true ? setAboutPackageOpen(false) : setAboutPackageOpen(true);
    }
    const preprartionToggleButton = () => {
        testPreparationOpen === true ? setTestPreparationOpen(false) : setTestPreparationOpen(true);
    }
    const vendorToggleButton = () => {
        vendorOpen === true ? setVendorOpen(false) : setVendorOpen(true);
    }

    useEffect(() => {
        async function getSingleProduct() {
            const response = await axios.get(`https://api.medibiotest.com/package/websitePackages?id=${id}`)
            const singleProducts1 = response.data.results
            setSingleProducts(singleProducts1)
        }
        getSingleProduct()
    }, [])

    async function getSingleProduct(body) {
        const response = await axios.post(`https://api.medibiotest.com/lead/generateLeadWebsite`, body)
        const singleProducts1 = response.data
        setSingleProducts1(singleProducts1)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const body = {
            fullName: firstName + lastName,
            email: email,
            mobile: mobile,
            city: city,
            source: source,
            package_id: id
        }
        getSingleProduct(body)
        setFirstName('');
        setLastName('');
        setEmail('');
        setMobile('');
        setCity('');
        setSource('')

        if (singleProducts1 && singleProducts1?.patient_name !== '') {
            setMessage("Success!!!!!")
            setOpenSuccessMessage(true);
        }
    }

    const handleCloseSuccess = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessMessage(false);
    };
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    useEffect(() => {
        setSource("medibio")
    })

    return (
        <>
            <Snackbar open={openSuccessMessage} autoHideDuration={6000} onClose={handleCloseSuccess}>
                <Alert onClose={handleCloseSuccess} severity="success" >
                    Booked Successfully!
                </Alert>
            </Snackbar>
            <Grid container padding={5}>
                <Grid item container xs={12} sm={12} md={12} lg={9}>
                    <Grid container justifyContent="start">
                        <Grid item xs={12} sm={6} md={6} lg={5.5} className="box-shadow" borderRadius={2} padding={2}>
                            <div className="imageStyle" >
                                <img src="https://www.hindustantimes.com/ht-img/img/2023/06/30/1600x900/national_doctors_day_1688133615866_1688133630147.jpg" alt="" />
                            </div>
                        </Grid>
                        <Grid item  xs={12} sm={6} md={6} lg={5.5} padding={1} paddingLeft={5}>
                            <div className="testname" >
                                {singleProducts && singleProducts[0]?.test_name}
                            </div>
                            <div className="testincluded">
                                1 test included
                            </div>
                            <div className="fontsize-20">
                                {singleProducts && singleProducts[0]?.test_name}
                            </div>
                        </Grid>
                    </Grid>


                    <Grid container paddingTop={6} >
                        <button onClick={packageToggleButton} style={{ width: '100%', border: '0', backgroundColor: "white", padding: '0' }}>
                            <Grid item container justifyContent="space-between" xs={12} sm={12} md={11} lg={11} border={1} borderRadius={3} padding={2} className="expand-grid">
                                <Grid item container justifyContent="left" xs={9} sm={8} md={8} lg={9}>
                                    <div className="detailsname">
                                        About Package
                                    </div>
                                </Grid>
                                <Grid item container xs={3} sm={4} md={4} lg={2} justifyContent="right">
                                    {expantMoreIcon}
                                </Grid>

                            </Grid>
                        </button>
                        <Grid item xs={12} sm={12} md={11} lg={11} >
                            {
                                aboutPackageOpen ? <div className="packagedetails">
                                    <div className="fontsize-25">
                                        Package Name :  <b>{singleProducts && singleProducts[0]?.test_name}</b>
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        1 Test included
                                    </div>
                                    <div className="singleproduct">
                                        {singleProducts && singleProducts[0]?.components}
                                    </div>
                                </div> : ''
                            }
                        </Grid>
                    </Grid>
                    <Grid container paddingTop={3} >
                        <button onClick={preprartionToggleButton} style={{ width: '100%', border: '0', backgroundColor: "white", padding: '0' }}>
                            <Grid item container justifyContent="space-between" xs={12} sm={12} md={11} lg={11} border={1} padding={2} borderRadius={3} className="expand-grid">
                                <Grid item container justifyContent="left" xs={9} sm={8} md={8} lg={9}>
                                    <div className="detailsname">
                                        Test Preparation
                                    </div>
                                </Grid>
                                <Grid item container xs={3} sm={4} md={4} lg={2} justifyContent="right">
                                    {expantMoreIcon}
                                </Grid>

                            </Grid>
                        </button>
                        <Grid item xs={12} sm={12} md={11} lg={11}>
                            {
                                testPreparationOpen ?
                                    <div className="packagedetails">
                                        <div className="fontsize-25">
                                            <b>{singleProducts && singleProducts[0]?.test_name}</b> Preparation
                                        </div>
                                        <ul className="singleproduct">
                                            <li>No Preparation Needed</li>
                                            <li> Consult your doctor, if on medication</li>
                                        </ul>
                                    </div> : ''
                            }
                        </Grid>
                    </Grid>
                    <Grid container paddingTop={2}>
                        <button onClick={vendorToggleButton} style={{ width: '100%', border: '0', backgroundColor: "white", padding: '0' }}>
                            <Grid item container justifyContent="space-between" xs={12} sm={12} md={11} lg={11} border={1} padding={2} borderRadius={3} className="expand-grid">
                                <Grid item container justifyContent="left" xs={9} sm={8} md={8} lg={9}>
                                    <div className="detailsname">
                                        Vendor
                                    </div>
                                </Grid>
                                <Grid item container xs={3} sm={4} md={4} lg={2} justifyContent="right">
                                    {expantMoreIcon}
                                </Grid>

                            </Grid>
                        </button>
                        <Grid item xs={12} sm={12} md={11} lg={11}>
                            {
                                vendorOpen ? <div className="packagedetails">
                                    <Grid item container xs={12} sm={12} md={12} lg={12} paddingLeft={2} padding={2} borderRadius={2} style={{ backgroundColor: 'lightskyblue' }}>
                                        <Grid item xs={12} sm={12} md={9} lg={8} paddingTop={1}>
                                            <div className="fontsize-25">Find Vendors Near To You</div>
                                        </Grid>
                                        <Grid item xs={8} sm={5} md={4} lg={2.7} paddingTop={1}>
                                            <input className="pincode-input" placeholder="Enter Pincode here" />
                                        </Grid>
                                        <Grid item xs={2.5} sm={1.5} md={1} lg={1} paddingLeft={1} paddingTop={1}>
                                            <Button variant="contained" sx={buttonStyle}>Apply</Button>
                                        </Grid>
                                    </Grid>

                                    <Grid item container className="box-shadow" marginTop={3} padding={3} xs={12} sm={7} md={6} lg={4}>
                                        <img className="logo-style" src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/medi-logo.jpg" alt="logo" />

                                        <Grid borderBottom={1} paddingTop={2} paddingBottom={1} className="fontsize-18-bold">
                                            Medi BioTest Prefered Vendors
                                        </Grid>
                                        <Grid item xs={10} sm={10} md={10} lg={10} paddingTop={3} className="fontsize-18">City -</Grid>
                                        <Grid item xs={10} sm={10} md={10} lg={10} paddingTop={1} className="fontsize-18">
                                            Address - PAN india
                                        </Grid>
                                        <Grid item xs={10} sm={10} md={10} lg={10} paddingTop={2} className="fontsize-18">
                                            <div className="mbt-price">
                                                ₹ {singleProducts && singleProducts[0]?.mbt_price}/-
                                            </div>
                                        </Grid>

                                        <button type="button" onClick={handleOpen} className="booknow-button">Book Now</button>
                                    </Grid>
                                </div> : ''
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item  className="box-shadow" style={{ height: '70%' }} padding={3} marginTop={4} xs={12} sm={6} md={4} lg={3}>
                    <Grid borderBottom={1} paddingBottom={1} className="testname"  >Selected Packages</Grid>
                    <div className="fontsize-20">Offer Price</div>
                    <div className="testname" >
                        ₹ {singleProducts && singleProducts[0]?.MRP}
                    </div>
                    <div style={{ padding: '10px', background: '#f8f8f8', borderRadius: '10px', marginTop: '10px' }}>52% off (You Saved RS 100) </div>
                    <div style={{ backgroundColor: '#e7f8f0', padding: '10px', marginTop: '10px', width: '150px', borderRadius: '10px' }}>
                        <div>Discount Price</div>
                        <div>
                            ₹ {singleProducts && singleProducts[0]?.mbt_price}
                        </div>
                    </div>
                    <button type="button" onClick={handleOpen} className="booknow-button">Book Now</button>
                </Grid>
                <Grid item container xs={12} sm={12} md={12} lg={12} alignItems="center" marginTop={3} padding={2} borderRadius={2} style={{ backgroundColor: 'lightGray' }}>
                    <Grid item xs={11} sm={6} md={8} lg={9.5}>
                        <div className="testname" >
                            Total WBC Count
                        </div>
                    </Grid>
                    <Grid item xs={7} sm={3.5} md={2.5} lg={1.5}>
                        <div className="total-price">Total Price {singleProducts && singleProducts[0]?.mbt_price}/-</div>
                    </Grid>
                    <Grid item xs={5} sm={2.5} md={1.5} lg={1}>
                        <button type="button" onClick={handleOpen} className="booknow-button">Book Now</button>
                    </Grid>
                </Grid>
            </Grid >


            {/* Login Modal starts */}
         
          {/*   <Modal open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box sx={style} className="box-shadow">
                    <Grid container justifyContent="space-between" alignItems="center" >
                        <Grid item xs={6} sm={6} md={11} lg={11}>
                            <div className="fontsize-20-bold">
                                Login
                            </div>
                        </Grid>
                        <Grid item xs={6} sm={6} md={1} lg={1} justifyContent="right">
                            <CancelOutlinedIcon onClick={handleClose} style={{ color: 'green', fontSize: '25px',cursor:'pointer' }} />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" marginTop={2} className="box-shadow" >
                        <Grid item container xs={10} sm={10} md={6} lg={6} justifyContent="center" >
                            <Grid item xs={11} sm={11} md={10} lg={10}>
                                <div className="fontsize-20-bold">
                                    Mobile Number
                                </div>
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={2}>
                                <input style={{ width: '100%', fontSize: '16px', padding: '10px', borderRadius: '5px' }} placeholder="Enter Pincode here..." />
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={4}>
                                <Button variant="contained" sx={buttonStyle}>Continue</Button>
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={3} marginTop={3} borderTop={1}>
                               You will receive an OTP Shortly <br />We will send Appointment - related communication on this number
                            </Grid>
                        </Grid>
                        <Grid item container xs={10} sm={10} md={6} lg={6} paddingTop={3} paddingBottom={3} justifyContent="center" >
                            <Grid item xs={11} sm={11} md={10} lg={10}> 
                             <img style={{ height: '100%', width: '100%' }} src="https://www.hindustantimes.com/ht-img/img/2023/06/30/1600x900/national_doctors_day_1688133615866_1688133630147.jpg" alt="" />
                           
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={2}>
                             <b> Sign In</b>
                            </Grid>
                            <Grid item xs={11} sm={11} md={10} lg={10} paddingTop={3}>
                               Sign in with <br />One Time Password(OTP)
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal> */}
            {/* Login Modal ends */}



           <Modal open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <Box  className="book-now-form" >
                    <Grid container justifyContent="center" alignItems="center" >
                        <Grid item xs={4} sm={3} md={5} lg={5}>
                            <img className="modalpopup-logo"  src="https://medibiotest-gallery.s3.ap-south-1.amazonaws.com/medi-logo.jpg" alt="logo" />
                        </Grid>
                        <Grid item container xs={6} sm={6} md={5} lg={5} justifyContent="right">
                            <CancelOutlinedIcon onClick={handleClose} className="close-icon" style={{fontSize:'30px'}}/>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12} paddingTop={3} >
                        <div className="package-booking-form">
                            Package Booking Form
                        </div>
                         </Grid>
                    <Grid container justifyContent="center" xs={12} sm={12} md={12} lg={12} paddingLeft={2} paddingRight={2}>
                       <div className="test-package-reserve">
                       Let's reserve test package! Please complete the form with all necessary informations!
                       </div>
                    </Grid >
                    <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="center" spacing={3} paddingTop={4}>
                            <Grid item xs={11} sm={11} md={5} lg={5}>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    className="form-control"
                                    value={firstName}
                                    placeholder="first name"
                                    required
                                    onChange={((e) => setFirstName(e.target.value))}
                                />
                            </Grid>
                            <Grid item xs={11} sm={11} md={5} lg={5}>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={lastName}
                                    className="form-control"
                                    placeholder="last name"
                                    required
                                    onChange={((e) => setLastName(e.target.value))}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" spacing={3} paddingTop={2}>
                            <Grid item xs={11} sm={11} md={5} lg={5} >
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    className="form-control"
                                    placeholder="email"
                                    required
                                    onChange={((e) => setEmail(e.target.value))}
                                />
                            </Grid>
                            <Grid item xs={11} sm={11} md={5} lg={5} paddingTop={2}>
                                <input
                                    type="tel"
                                    id="moblieNumer"
                                    name="moblieNumer"
                                    value={mobile}
                                    className="form-control"
                                    placeholder="moblie numer"
                                    maxLength={10}
                                    minLength={10}
                                    required
                                    onChange={((e) => setMobile(e.target.value))}
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" spacing={3} paddingTop={2}>
                            <Grid item xs={11} sm={11} md={5} lg={5}>
                                <input
                                    type="text"
                                    name="city"
                                    value={city}
                                    className="form-control"
                                    placeholder="city"
                                    required
                                    onChange={((e) => setCity(e.target.value))}
                                />
                            </Grid>
                            <Grid item xs={11} sm={11} md={5} lg={5} paddingTop={2}>
                                <input
                                    id="source"
                                    name="source"
                                    value={source}
                                    className="form-control"
                                    placeholder="source"
                                    required
                                />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center" spacing={3} paddingTop={4}>
                            <Grid item xs={11} sm={11} md={5} lg={5}>
                                <button type="submit" className="book-package-buttton" >Book Now</button>
                            </Grid>
                        </Grid>
                    </form>
                </Box>
            </Modal>  
        </>
    );
}
export default SingleProductPage;