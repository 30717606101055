import { Button, Card, Grid, TextField } from "@mui/material";
import React, { useState,useEffect } from "react";
import AllTestDetails from "./allTestDetails";
import axios from "axios";
import './allTest.css';
import AllPackages from "../testpackages/allPackages";
import { genrateAdminFilter } from "../../../generateUrl";

const cardStyle = {
    borderRadius: '10px',
    boxShadow: '2px 4px 14px 0px #00000026',
    height: '350px',
    "&:hover": {
        background: 'green'
    }
}

const AllTest = () => {
    const [testPackage, setTestPackage] = useState([]);
    const [inputList, setInputList] = useState([
        {
            testName: "",
        },
    ]);


    async function getPackage(filter) {
        try {
            const response = await axios.get(`https://api.medibiotest.com/package/websitePackages${filter ? filter : ""}`)
            const result = response.data.results
            setTestPackage(result)
        }
        catch (error) {
            if (error.response && error.response.status === 400) {
                setTestPackage('')
            }
        }

    }
    useEffect(() => {
        getPackage()
    }, [])
    // getPackage()

    console.log("testPackage", testPackage)

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    }
    const handleSubmit = async (e) => {
        const body = {
            test_name: inputList[0].testName,
            full_name: inputList[0].customerName,
            phone: inputList[0].contactNumber,
            booking_date: inputList[0].bookingDate,
            start_time: inputList[0].startTime,
            end_time: inputList[0].endTime,
            collectionDate: inputList[0].collection_date,
        }
      const url = genrateAdminFilter(body).substring(2);
        getPackage(url ? `?${url}` : "")
    }

    const handle = (e, index) => {
        handleInputChange(e, index)
        handleSubmit(e)
    }
    const handleResetFilter=()=>{
        setInputList([{testName:''}])
        getPackage()
    }

    return (
        <>
            <AllPackages />
            <div>
                <Grid container justifyContent="center" paddingTop={5}>
                    <Grid item xs={9} sm={10} md={10.5} lg={10} paddingLeft={2} borderRadius={3}>
                        <div style={{ fontSize: "22px", fontWeight: '400' }}>
                            ALL TESTS
                        </div>
                    </Grid>
                    <Grid item container xs={9} sm={10} md={10.5} lg={10} paddingLeft={2} borderRadius={3}>
                        <Grid item xs={12} sm={12} md={9} lg={8} paddingTop={1}>
                            <div className="popular-lab-test" >
                                <div className="popular-lab-test"><b>Book</b> Popular Routine <b>Lab Tests</b></div>
                            </div>
                        </Grid>
                        {inputList.map((x, index) => {
                            return (
                                <>
                                    <Grid item xs={6} sm={5} md={4} lg={2.7} paddingTop={1}>
                                        <input
                                            name="testName"
                                            value={x.testName || ''}
                                            style={{ width: '100%', fontSize: '16px', padding: '10px', borderRadius: '5px' }}
                                            placeholder="Search lab test by name"
                                            onChange={(e) => handle(e, index)}
                                        />
                                    </Grid>

                                </>
                            )
                        })}
                        <Grid item xs={2.5} sm={1.5} md={1} lg={1} paddingLeft={1} paddingTop={1}>
                            <Button onClick={handleResetFilter} variant="contained" sx={{ width: '100%', height: '45px', fontSize: '15px', fontWeight: '600', backgroundColor: '#075eab', color: 'white' }}>Clear</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container justifyContent="center">
                    {testPackage && testPackage.length >= 0 && testPackage.map((item) =>
                        <Grid item xs={9} sm={5} md={3.5} lg={2.5} padding={2} borderRadius={3}>
                            <Card style={cardStyle}>
                                <AllTestDetails item={item} />
                            </Card>
                        </Grid>
                    )}

                </Grid>
            </div>
        </>
    );
}
export default AllTest;