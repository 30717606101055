import { Grid } from "@mui/material";
import React,{useEffect} from "react";
import './virtualconsulation.css'
import VirtualDrDetails from "./virtualdoctordetails";
import { VirtualDrData } from "./virtualdocotorData";
import VirtualConsultBenefits from "./benefitsofvirtualconsults";
import VirtualSpecilityDetails from "./virtualspecilitydetails";
import MicIcon from '@mui/icons-material/Mic';
import ConsultaionQueries from "./anyquesries";

const VirtuaConsulation = () => {
    useEffect(()=>{
        window.scroll(0,0)
    },[])
    return (
        <>
         <Grid container justifyContent="center" padding={5}>
            <Grid item xs={10} sm={10} md={10.5} lg={10.5}>
            <div className="vconsulation-image">
                <img alt="" src="https://www.raphacure.com/img/banners/BannerNew/Doctor-onsultation-Clinical.webp" />
            </div>
            </Grid>
        </Grid>
            <Grid container justifyContent="center" paddingTop={5}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <div className="avail-unlimited-doctor">Avail Unlimited Doctor</div>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center" spacing={2} padding={2} style={{ width: '83%', marginLeft: '7.5%', boxShadow: '2px 4px 14px 2px #00000026' }} marginTop={5}>
                <Grid item xs={10} sm={10} md={2.5} lg={3}>
                    <input className="vconsult-input vconsult-inputbox" type="text" placeholder="Search, Doctor, Specialist" />
                </Grid>
                <Grid item xs={3} sm={3} md={1} lg={1}>
                    <button className="vconsult-input mic-icon"><MicIcon color="success" style={{fontSize:'20px'}} /></button>
                </Grid>
                <Grid item container justifyContent="center" xs={7} sm={7} md={3} lg={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <button className="vconsult-input virtual-consult-button">Virtual</button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <button className="vconsult-input clinic-consult-button">Clinic</button>
                    </Grid>
                </Grid>
                <Grid item xs={5} sm={5} md={1.5} lg={2}>
                    <select className="vconsult-input vconsult-select">
                        <option>English</option>
                        <option>Hindu</option>
                    </select>
                </Grid>
                <Grid item xs={5} sm={5} md={1.5} lg={2}>
                    <select className="vconsult-input vconsult-select">
                        <option>Male</option>
                        <option>Female</option>
                    </select>
                </Grid>
                <Grid item container justifyContent="center" spacing={1} xs={10} sm={10} md={2.5} lg={2}>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <button className="vconsult-input filter-button">Search</button>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} lg={6}>
                        <button className="vconsult-input filter-button">Clear</button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" paddingTop={5}>
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <div className="avail-unlimited-doctor">Doctors available in</div>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item xs={10} sm={10} md={10} lg={10}>
                    <div className="verified-doctor-details">Book Appointments with minimum wait-time & verified doctor details</div>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" padding={3} spacing={6}>
                <Grid item xs={10} sm={10} md={6} lg={6.3}>
                    {
                        VirtualDrData && VirtualDrData.map((doctorDetails) =>
                            <VirtualDrDetails doctorDetails={doctorDetails} />
                        )
                    }
                </Grid>
                <Grid item padding={5} xs={11} sm={10} md={4.5} lg={3.7}>
                    <div className="virtualdoctor-sideimage">
                        <img alt="" src="https://media.istockphoto.com/id/1189304032/photo/doctor-holding-digital-tablet-at-meeting-room.jpg?s=612x612&w=0&k=20&c=RtQn8w_vhzGYbflSa1B5ea9Ji70O8wHpSgGBSh0anUg=" />
                    </div>
                </Grid>
            </Grid>
            <Grid container justifyContent="center" className="vconsult-specilities" marginTop={7}>
                <div className="vconsult-specility">How It Works</div>
                <VirtualSpecilityDetails />
            </Grid>
            <Grid container justifyContent="center" paddingTop={5}>
                <div className="vconsult-benefits">Benefits of Online Consultation</div>
                <VirtualConsultBenefits />
            </Grid>
            <Grid container justifyContent="center" paddingTop={10}>
                <ConsultaionQueries />
            </Grid>
        </>
    );
}
export default VirtuaConsulation;