import React from "react";
import { Grid } from "@mui/material";
import './virtualconsulation.css';

const specilityData = [
    {
        id: 1,
        img: 'https://img.freepik.com/free-photo/portrait-smiling-young-woman-doctor-healthcare-medical-worker-pointing-fingers-left-showing-clini_1258-88108.jpg',
        specility: 'Select a Specility or Symptom',
    },
    {
        id: 2,
        img: 'https://img.freepik.com/free-photo/portrait-smiling-young-woman-doctor-healthcare-medical-worker-pointing-fingers-left-showing-clini_1258-88108.jpg',
        specility: 'Audio/Video call with a verified Doctor',
    },
    {
        id: 3,
        img: 'https://img.freepik.com/free-photo/portrait-smiling-young-woman-doctor-healthcare-medical-worker-pointing-fingers-left-showing-clini_1258-88108.jpg',
        specility: 'Get a digital prescription & a free follow-up',
    }
]


const VirtualSpecilityDetails = () => {
    return (
        <>
            <Grid container justifyContent="center">
                {
                    specilityData.map(data => {
                        return (
                            <>
                                <Grid className="specility-main" margin={6} padding={3} item xs={10} sm={5} md={3.2} lg={3.2}>
                                   <div>
                                   <div className="specility-conatiner" >
                                        <div className="specility-image">
                                            <img src={data.img} />
                                        </div>

                                    </div>
                                </div>
                                <div className="specility">{data.specility}
                                   </div>
                                </Grid>

                            </>
                        )
                    }
                    )
                }
            </Grid>
        </>
    );
}
export default VirtualSpecilityDetails;