import React from "react";
import CheckIcon from '@mui/icons-material/Check';
import { Grid } from "@mui/material";
import './virtualconsulation.css';

const benefitsData = [
    {
        id: 1,
        benefit: 'Consult Top Doctors 24x7',
        explaination: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
        id: 2,
        benefit: 'Convenient and Easy',
        explaination: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
        id: 3,
        benefit: '100% Safe Consultations',
        explaination: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
        id: 4,
        benefit: 'Similar Clinic Experience',
        explaination: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
    {
        id: 5,
        benefit: 'Free Follow-up',
        explaination: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'
    },
]


const VirtualConsultBenefits = () => {
    return (
        <>
         <Grid container justifyContent="center">
            {
                 benefitsData.map(data => {
                    return (
                        <>
                                <Grid className="benefit-main" margin={3} padding={3} item xs={10} sm={5} md={3.2} lg={3.2}>
                                    <div className="benefit-container" >
                                        <div className="benefit">
                                         <CheckIcon style={{fontSize:'20px'}}/>   {data.benefit}
                                        </div>
                                        <div className="benefit-explanation">
                                            {data.explaination}
                                        </div>
                                    </div>
                                </Grid>
                            
                        </>
                    )
                }
                )
            }
            </Grid>
        </>
    );
}
export default VirtualConsultBenefits;